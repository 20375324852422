<template>
  <div id="mapa">
    <loading
      :active="loading || !organizacao || base64ImageFromChart == ''"
      :is-full-page="true"
    ></loading>
    <seall-header></seall-header>

    <main class="main-map">
      <div class="div-legacy-cards">
        <carousel-cards></carousel-cards>
        <div class="div-legacy-cards-img">
          <img
            :src="
              organizacao && organizacao.photoPath
                ? organizacao.photoPath
                : require('./../images/img-example.png')
            "
            alt
          />
        </div>
      </div>
      <div class="page-map-results">
        <div class="map-infos-organization" v-if="organizacao">
          <h2>{{ $t("map.title") }}</h2>
          <h3>{{ organizacao.organizationInitiative }}</h3>
          <span>{{ organizacao.name }}</span>
          <div class="map-infos-organization-line">
            <p v-if="!organizacao.segment"></p>
            <p v-else>
              {{ $t("organization.areas." + organizacao.segment + ".text") }}
            </p>
            <p>{{ organizacao.city }}, {{ organizacao.country }}</p>
          </div>

          <div v-if="!!organizacao.mission" class="map-missao-organization">
            <h2>{{ $t("map.mission") }}</h2>
            <h3>{{ organizacao.mission }}</h3>
          </div>
        </div>

        <div class="map-infos-map" v-show="true" v-if="current_stakeholder">
          <h2 style="text-transform: uppercase">{{ $t("map.map") }}</h2>
          <h3>{{ $t("map.map_info") }}</h3>

          <div class="map-points">
            <carousel :items-to-show="valor_map" style="width: 100%">
              <slide key="slide">
                <div class="map-points-line">
                  <span>{{ $t("map.mapwords.para.nome") }}</span>
                  <div
                    class="map-points-card checkbox bounce"
                    v-for="forWho in mapByStakeholders[current_stakeholder]
                      .forWho"
                    v-bind:key="forWho.key"
                    v-bind:style="{
                      'border-color': getColorFromStakeholder(forWho),
                    }"
                  >
                    <i
                      class="material-icons cursor-pointer"
                      @click="changeCurrentStakeholder(forWho.value)"
                      v-if="forWho.value != current_stakeholder"
                    >
                      radio_button_unchecked
                    </i>
                    <i
                      class="material-icons cursor-pointer bg-checked"
                      @click="changeCurrentStakeholder(forWho.value)"
                      v-if="forWho.value === current_stakeholder"
                    >
                      radio_button_checked
                    </i>
                    <p
                      style="text-align: left"
                      v-bind:style="{
                        color: getColorFromStakeholder(forWho),
                      }"
                    >
                      {{ forWho.value }}
                    </p>
                    <input
                      type="checkbox"
                      :disabled="forWho.value != current_stakeholder"
                      v-bind:style="{
                        'background-color':
                          getCurrentColorFromCurrentStakeholder(forWho.value),
                      }"
                      :checked="
                        verifyConnections(forWho.key) &&
                        forWho.value === current_stakeholder
                      "
                      @click="changeMap('from', 'forWho', forWho)"
                      :id="'forWho-from-' + forWho.key"
                    />
                  </div>
                </div>
              </slide>
              <slide key="slide">
                <div class="map-points-line">
                  <span>{{ $t("map.mapwords.problemas.nome") }}</span>
                  <div
                    class="map-points-card checkbox bounce"
                    v-for="knowledgeProblem in mapByStakeholders[
                      current_stakeholder
                    ].knowledgeProblem"
                    v-bind:key="knowledgeProblem.key"
                  >
                    <input
                      type="checkbox"
                      v-bind:style="{
                        'background-color': getColorFromCurrentStakeholder(
                          knowledgeProblem,
                          'to'
                        ),
                      }"
                      :checked="verifyConnections(knowledgeProblem, 'to')"
                      :disabled="disabled_click.knowledgeProblem.to"
                      :id="'knowledgeProblem-to-' + knowledgeProblem.key"
                      @click="
                        changeMap('to', 'knowledgeProblem', knowledgeProblem)
                      "
                    />
                    <p>{{ knowledgeProblem.value }}</p>
                    <input
                      type="checkbox"
                      v-bind:style="{
                        'background-color': getColorFromCurrentStakeholder(
                          knowledgeProblem,
                          'from'
                        ),
                      }"
                      :checked="verifyConnections(knowledgeProblem)"
                      :disabled="disabled_click.knowledgeProblem.from"
                      :id="'knowledgeProblem-from-' + knowledgeProblem.key"
                      @click="
                        changeMap('from', 'knowledgeProblem', knowledgeProblem)
                      "
                    />
                  </div>
                </div>
              </slide>
              <slide key="slide">
                <div class="map-points-line">
                  <span>{{ $t("map.mapwords.propomos.nome") }}</span>
                  <div
                    class="map-points-card checkbox bounce"
                    v-for="solution in mapByStakeholders[current_stakeholder]
                      .solution"
                    v-bind:key="solution.key"
                  >
                    <input
                      type="checkbox"
                      :disabled="disabled_click.solution.to"
                      v-bind:style="{
                        'background-color': getColorFromCurrentStakeholder(
                          solution,
                          'to'
                        ),
                      }"
                      :checked="verifyConnections(solution, 'to')"
                      @change="changeMap('to', 'solution', solution)"
                      :id="'solution-to-' + solution.key"
                    />
                    <p>{{ solution.value }}</p>
                    <input
                      type="checkbox"
                      :disabled="disabled_click.solution.from"
                      v-bind:style="{
                        'background-color': getColorFromCurrentStakeholder(
                          solution,
                          'from'
                        ),
                      }"
                      :checked="verifyConnections(solution)"
                      @change="changeMap('from', 'solution', solution)"
                      :id="'solution-from-' + solution.key"
                    />
                  </div>
                </div>
              </slide>
              <slide key="slide">
                <div class="map-points-line">
                  <span>{{ $t("map.mapwords.atividades.nome") }}</span>
                  <div
                    class="map-points-card checkbox bounce"
                    v-for="activity in mapByStakeholders[current_stakeholder]
                      .activity"
                    v-bind:key="activity.key"
                  >
                    <input
                      type="checkbox"
                      :disabled="disabled_click.activity.to"
                      v-bind:style="{
                        'background-color': getColorFromCurrentStakeholder(
                          activity,
                          'to'
                        ),
                      }"
                      :checked="verifyConnections(activity, 'to')"
                      @change="changeMap('to', 'activity', activity)"
                      :id="'activity-to-' + activity.key"
                    />
                    <p>{{ activity.value }}</p>
                    <input
                      type="checkbox"
                      :disabled="disabled_click.activity.from"
                      v-bind:style="{
                        'background-color': getColorFromCurrentStakeholder(
                          activity,
                          'from'
                        ),
                      }"
                      :checked="verifyConnections(activity)"
                      @change="changeMap('from', 'activity', activity)"
                      :id="'activity-from-' + activity.key"
                    />
                  </div>
                </div>
              </slide>
              <slide key="slide">
                <div class="map-points-line">
                  <span>{{ $t("map.mapwords.medimos.nome") }}</span>
                  <div
                    class="map-points-card checkbox bounce"
                    v-for="effect in mapByStakeholders[current_stakeholder]
                      .effect"
                    v-bind:key="effect.key"
                  >
                    <input
                      type="checkbox"
                      :disabled="disabled_click.effect.to"
                      v-bind:style="{
                        'background-color': getColorFromCurrentStakeholder(
                          effect,
                          'to'
                        ),
                      }"
                      :checked="verifyConnections(effect, 'to')"
                      @change="changeMap('to', 'effect', effect)"
                      :id="'effect-to-' + effect.key"
                    />
                    <p>{{ effect.value }}</p>
                    <input
                      type="checkbox"
                      :disabled="disabled_click.effect.from"
                      v-bind:style="{
                        'background-color': getColorFromCurrentStakeholder(
                          effect,
                          'from'
                        ),
                      }"
                      :checked="verifyConnections(effect)"
                      @change="changeMap('from', 'effect', effect)"
                      :id="'effect-from-' + effect.key"
                    />
                  </div>
                </div>
              </slide>
              <slide key="slide">
                <div class="map-points-line">
                  <span>{{ $t("map.mapwords.resultados.nome") }}</span>
                  <div
                    class="map-points-card checkbox bounce"
                    v-for="result in mapByStakeholders[current_stakeholder]
                      .result"
                    v-bind:key="result.key"
                  >
                    <input
                      type="checkbox"
                      :disabled="disabled_click.result.to"
                      v-bind:style="{
                        'background-color': getColorFromCurrentStakeholder(
                          result,
                          'to'
                        ),
                      }"
                      :checked="verifyConnections(result, 'to')"
                      @change="changeMap('to', 'result', result)"
                      :id="'result-to-' + result.key"
                    />
                    <p>{{ result.value }}</p>
                    <input
                      type="checkbox"
                      :disabled="disabled_click.result.from"
                      v-bind:style="{
                        'background-color': getColorFromCurrentStakeholder(
                          result,
                          'from'
                        ),
                      }"
                      :checked="verifyConnections(result)"
                      @change="changeMap('from', 'result', result)"
                      :id="'result-from-' + result.key"
                    />
                  </div>
                </div>
              </slide>
              <slide key="slide">
                <div class="map-points-line">
                  <span>{{ $t("map.mapwords.solucoes.nome") }}</span>
                  <div
                    class="map-points-card checkbox bounce"
                    v-for="impact in mapByStakeholders[current_stakeholder]
                      .impact"
                    v-bind:key="impact.key"
                  >
                    <input
                      type="checkbox"
                      :disabled="disabled_click.impact.to"
                      v-bind:style="{
                        'background-color': getColorFromCurrentStakeholder(
                          impact,
                          'to'
                        ),
                      }"
                      :checked="verifyConnections(impact, 'to')"
                      @change="changeMap('to', 'impact', impact)"
                      :id="'impact-to-' + impact.key"
                    />
                    <p style="text-align: right">{{ impact.value }}</p>
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
        </div>

        <div class="map-radar-ods">
          <h2 style="text-transform: uppercase">{{ $t("map.radar") }}</h2>
          <h3>{{ $t("map.radar_info") }}</h3>
          <div class="map-radar-graph-itens">
            <img
              class="mostrar-svg"
              v-if="mostrar"
              key="img-onu"
              src="./../images/onu.svg"
              alt
            />
            <div
              class="mostrar-div"
              v-bind:style="{ backgroundColor: colors[index] }"
              v-else
            >
              <p v-if="$i18n.locale == 'pt'">
                {{ series[index].toFixed(1).toString().replace(".", ",") }}%
              </p>
              <p v-if="$i18n.locale != 'pt'">{{ series[index].toFixed(1) }}%</p>
              <img
                :src="require('./../images/ods-icon' + id[index] + '.svg')"
              />
            </div>
            <canvas class="chart" width="900" height="450"></canvas>
          </div>

          <div class="map-radar-ods-graph">
            <div class="map-radar-cards">
              <div
                class="map-radar-card"
                v-for="(dados, index) in radar"
                v-bind:key="dados"
                :style="{ 'background-color': dados.odsColor }"
                v-on:click.prevent="change_ods(index)"
              >
                <div class="map-radar-card-text">
                  <p>{{ dados.ods }}</p>
                  <span>{{ $t("map.ods.ods" + dados.ods + ".name") }}</span>
                </div>
                <div class="map-radar-card-img">
                  <img
                    :src="require('./../images/ods-icon' + dados.ods + '.svg')"
                  />
                </div>
              </div>
            </div>

            <div class="map-radar-graph"></div>
          </div>

          <div class="map-radar-ods-details" v-if="this.radar[this.index]">
            <div class="map-radar-ods-details">
              <div class="map-radar-details">
                <div
                  class="map-radar-details-title"
                  v-on:click.prevent="open_ods()"
                  :style="{
                    'background-color': this.radar[this.index].odsColor,
                  }"
                >
                  <img
                    :src="
                      require(`./../images/ods-icon${
                        this.radar[this.index].ods
                      }.svg`)
                    "
                  />
                  <h2>{{ this.radar[this.index].ods }}</h2>
                  <h3>
                    {{ $t("map.ods.ods" + this.id[this.index] + ".name") }}
                  </h3>
                  <p v-if="!open" key="open_ods">
                    <i class="material-icons">expand_more</i>
                  </p>
                  <p v-else>
                    <i class="material-icons">expand_less</i>
                  </p>
                </div>

                <div class="map-radar-details-description">
                  <p class="map-radar-details-description-sub">
                    {{ $t("map.ods.ods" + this.id[this.index] + ".desc") }}
                  </p>
                  <span
                    v-if="!open"
                    key="clique"
                    v-on:click.prevent="open_ods()"
                  >
                    <b>{{ $t("map.texts.click_max") }}</b>
                  </span>
                  <div v-if="open">
                    <p
                      class="map-radar-details-description-desc"
                      key="open_ods"
                    >
                      {{ $t("map.ods.ods" + this.id[this.index] + ".text") }}
                    </p>
                    <a
                      class="map-radar-details-description-desc"
                      target="_blank"
                      :href="ods_onu_links['ods' + this.id[this.index]]"
                      >{{ $t("map.ods.sourcing") }}</a
                    >
                  </div>
                  <span v-if="open" v-on:click.prevent="open_ods()">
                    <b>{{ $t("map.texts.click_min") }}</b>
                  </span>
                </div>
                <div
                  class="map-radar-details-line"
                  v-on:click.prevent="open_ods()"
                >
                  <div
                    class="map-radar-details-line-color"
                    :style="{
                      'background-color': this.radar[this.index].odsColor,
                      width: this.radar[this.index].finalNote,
                    }"
                  ></div>
                </div>
                <div class="map-radar-details-footer">
                  <img src="./../images/ods-circle.svg" alt />
                  <h2 v-if="$i18n.locale == 'pt'">
                    {{ this.radar[this.index].finalNote.replace(".", ",") }}%
                  </h2>
                  <h2 v-if="$i18n.locale != 'pt'">
                    {{ this.radar[this.index].finalNote }}%
                  </h2>
                  <h3>{{ $t("map.texts.text_card") }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="map-radar-ods" v-if="esgDataIndividual.length > 0">

            <h2 style="text-transform: uppercase">Diagnóstico ESG</h2>
            <h3>
              O Diagnóstico ESG demonstra como a sua empresa está posicionada em relação ao
              grau de implementação de estratégias ESG, a partir das temáticas
              avaliadas no diagnóstico. A nota se apresenta em uma escala de 0% a
              100%, para cada eixo, Ambiental (E), Social (S) e de Governança (G).
            </h3>

            <div class="map-radar-graph-itens">
              <img
                  class="mostrar-svg-esg"
                  src="../images/esg-icon.svg"
                  v-if="mostrar_esg"
              />
              <div
                  class="mostrar-div-esg"
                  v-bind:style="{ backgroundColor: esgColors[index_esg] }"
                  v-else
              >
                <p>
                  {{
                    esgDataIndividual[index_esg]
                        .toFixed(1)
                        .toString()
                        .replace(".", ",")
                  }}%
                </p>
                <img :src="require('./../images/' + icons[index_esg] + '.png')" />
              </div>
              <canvas class="chart-esg" width="885" height="442"></canvas>
            </div>

            <div class="map-radar-ods-graph">
              <div class="map-radar-cards">
                <div
                    v-for="(eixo, index) in esg_labels"
                    v-bind:key="eixo"
                    class="map-radar-card"
                    :style="{ 'background-color': esgColors[index] }"
                    v-on:click.prevent="change_axle_esg(index)"
                >
                  <div class="map-radar-card-text">
                    <span>{{ eixo }}</span>
                  </div>
                  <div class="map-radar-card-img">
                    <img :src="require('./../images/' + icons[index] + '.png')" />
                  </div>
                </div>
              </div>

              <div class="map-radar-graph"></div>
            </div>

            <div class="map-radar-ods-details">
              <div class="map-radar-ods-details">
                <div class="map-radar-details">
                  <div
                      class="map-radar-details-title"
                      v-on:click.prevent="openEsgAxle()"
                      :style="{
                  'background-color': esgColors[index_esg],
                  'gap': '1rem'
                }"
                  >
                    <img :src="require(`./../images/${icons[index_esg]}.png`)" />
                    <h2 v-tooltip="esg_labels[index_esg]" :style="{'text-align': 'left'}" class="w-100 text-truncate">
                      {{ esg_labels[index_esg] }}
                    </h2>
                    <span v-if="open_esg" key="openEsgAxle">
                  <i class="material-icons">expand_more</i>
                </span>
                    <span v-else>
                  <i class="material-icons">expand_less</i>
                </span>
                  </div>

                  <div class="map-radar-details-description">
                    <p class="map-radar-details-description-sub">
                      {{ $t("esg." + esg_labels[index_esg] + ".title") }}
                    </p>
                    <span
                        v-if="!open_esg"
                        key="clique"
                        v-on:click.prevent="openEsgAxle()"
                    >
                      <b>{{ $t("map.texts.click_max") }}</b>
                    </span>
                    <div v-if="open_esg">
                      <p
                          class="map-radar-details-description-desc"
                          key="openEsgAxle"
                          v-html="$t('esg.' + esg_labels[index_esg] + '.description')"
                      ></p>
                    </div>
                    <span v-if="open_esg" v-on:click.prevent="openEsgAxle()">
                  <b>{{ $t("map.texts.click_min") }}</b>
                </span>
                  </div>
                  <div
                      class="map-radar-details-line"
                      v-on:click.prevent="openEsgAxle()"
                  >
                    <div
                        class="map-radar-details-line-color"
                        :style="{
                    'background-color': esgColors[index_esg],
                  }"
                    ></div>
                  </div>
                  <div class="map-radar-details-footer">
                    <img src="./../images/esg-icon.svg" alt />
                    <h2>
                      {{
                        esgDataIndividual[index_esg]
                            .toFixed(1)
                            .toString()
                            .replace(".", ",")
                      }}%
                    </h2>
                    <h3
                        v-tooltip="'de Alinhamento ao eixo ' + esg_labels[index_esg]"
                        class="text-truncate"
                    >
                      de Alinhamento ao eixo {{ esg_labels[index_esg] }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div class="main-content justify-content-between">

        </div>

      </div>
    </main>

    <map-email
      :organizacao="organizacao"
      :colors="colors"
      :radar="radar"
      :series="series"
      id="to-email"
      v-if="!loading"
      @chartBase64Image="chartBase64Image"
      v-show="base64ImageFromChart == ''"
    >
    </map-email>

    <footer class="footer-map">
      <img src="./../images/barra_ods.svg" alt="Barra" style="width: 100%" />

      <div id="shareModal" class="modalDialog">
        <div class="share-modal">
          <div class="share-modal-header">
            <div class="title">
              <h2>{{ $t("map.share.title") }}</h2>
              <sub>{{ $t("map.share.subtitle") }}</sub>
            </div>
            <i class="material-icons">share</i>
          </div>
          <div class="share-modal-content">
            <form @submit.prevent="shareMap()" class="share-form">
              <div class="form-content">
                <button class="btn-add" @click.prevent="addShareUser()">
                  <i class="material-icons">add</i>
                </button>

                <div
                  class="form-row"
                  :key="user"
                  v-for="(user, i) in share.users"
                >
                  <div class="form-item">
                    <label class="required">{{
                      $t("map.share.fields.name")
                    }}</label>
                    <input
                      type="text"
                      v-model="user.name"
                      :placeholder="$t('map.share.fields.name')"
                      required
                    />
                    <div
                      v-if="!isValidName(user.name)"
                      class="invalid-feedback"
                    >
                      {{ $t("map.share.invalid_field.name") }}
                    </div>
                  </div>
                  <div class="form-item">
                    <label class="required">{{
                      $t("map.share.fields.email")
                    }}</label>
                    <input
                      type="email"
                      v-model="user.email"
                      :placeholder="$t('map.share.fields.email')"
                      required
                    />
                    <div
                      v-if="!isValidEmail(user.email)"
                      class="invalid-feedback"
                    >
                      {{ $t("map.share.invalid_field.email") }}
                    </div>
                  </div>
                  <i
                    @click.prevent="removeShareUser(i)"
                    class="btn-delete material-icons"
                    >delete
                  </i>
                </div>
              </div>
              <div class="btn-actions">
                <a href="#close" title="Close" class="close-share-map">
                  <button type="button" class="btn-cancel">
                    {{ $t("map.share.buttons.cancel") }}
                  </button>
                </a>
                <button :disabled="!canShare" type="submit" class="btn-share">
                  {{ $t("map.share.buttons.share") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="div-map-footer-button">
        <powered-by></powered-by>
        <div class="actions">
          <button
            type="button"
            class="map-footer-button-back"
            @click="create_pdf()"
          >
            <i class="material-icons">file_download</i>
            {{ $t("map.button_download") }}
          </button>
          <a href="#shareModal">
            <button type="button" class="map-footer-button-back">
              <i class="material-icons">share</i>
              {{ $t("map.button_share") }}
            </button>
          </a>
        </div>
        <button type="button" class="map-footer-button" @click="logout()">
          {{ $t("main.logout") }}
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import _, {groupBy} from "lodash";
import { v4 as uuidv4 } from "uuid";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import AuthService from "../../services/auth/auth";
import OrganizationService from "../../services/organization/organization.service.js";
import SeallBasicLambdasService from "../../services/seall-basic-lambdas/seall-basic-lambdas.service";
import Validators from "../../utils/Validators.js";
import "../javascript/chart.bundle.min.js";
import "../javascript/chartjs-plugin-datalabels";
import "../javascript/chartjs-plugin-labels";
import CarouselCards from "./CarouselCards.vue";
import SeallHeader from "./Header.vue";
import MapEmail from "./MapEmail.vue";
import PoweredBy from "./PoweredBy.vue";
export default {
  name: "mapa",
  data() {
    return {
      valor: 4.5,
      valor_map: 4.5,
      valor_graph: 500,
      open: false,
      open_esg: false,
      open_ods_control: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      organizacao: null,
      mapa: {},
      radar: [],
      index: 0,
      chart: null,
      chart_esg: null,
      new_chart: false,
      mostrar: true,
      mostrar_esg: true,
      id: [],
      series: [],
      colors: [],
      loading: false,
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
      ods_onu_links: null,
      connects: [],
      current_stakeholder: "",
      current_item_from: null,
      current_from_source: null,
      current_from_key: null,
      current_from: null,
      current_action: "from",
      disabled_click: {
        activity: { from: false, to: false },
        effect: { from: false, to: false },
        forWho: { from: false, to: false },
        knowledgeProblem: { from: false, to: false },
        solution: { from: false, to: false },
        result: { from: false, to: false },
        impact: { from: false, to: false },
      },
      colorsUsingForStakeholder: [],
      ods_colors: [
        "#E5243B",
        "#DDA63A",
        "#4C9F38",
        "#C5192D",
        "#FF3A21",
        "#26BDE2",
        "#FCC30B",
        "#A21942",
        "#FD6925",
        "#DD1367",
        "#FD9D24",
        "#BF8B2E",
        "#3F7E44",
        "#0A97D9",
        "#56C02B",
        "#00689D",
        "#19486A",
      ],
      ods_number_colors: [
        { ods: 1, color: "#E5243B" },
        { ods: 2, color: "#DDA63A" },
        { ods: 3, color: "#4C9F38" },
        { ods: 4, color: "#C5192D" },
        { ods: 5, color: "#FF3A21" },
        { ods: 6, color: "#26BDE2" },
        { ods: 7, color: "#FCC30B" },
        { ods: 8, color: "#A21942" },
        { ods: 9, color: "#FD6925" },
        { ods: 10, color: "#DD1367" },
        { ods: 11, color: "#FD9D24" },
        { ods: 12, color: "#BF8B2E" },
        { ods: 13, color: "#3F7E44" },
        { ods: 14, color: "#0A97D9" },
        { ods: 15, color: "#56C02B" },
        { ods: 16, color: "#00689D" },
        { ods: 17, color: "#19486A" },
      ],
      mapByStakeholders: {},
      share: {
        users: [{ email: null, name: null }],
      },
      base64ImageFromChart: "",
      cogId: null,
      organization: null,
      quiz: null,
      axles: [],
      canSave: false,
      g4Completed: false,
      esgGraph: [],
      index_esg: 0,
      esg_labels: ["Ambiental", "Governança", "Social"],
      icons: ["esg-ambiente", "esg-governanca", "esg-social"],
      esgColors: ["#00B7BB", "#6A11CA", "#E65F0C"],
      esgData: [],
      esgDataIndividual: [],
      canDelete: false,
      orgCanDeleteResult: [
        "gabriel_castr@outlook.com.br",
        "gabriel@sealegacy.com.br",
        "ferollagfv@gmail.com",
      ],
    };
  },
  mounted() {
    this.cogId = AuthService.getCogId();
    const email = AuthService.getEmail();

    if (AuthService.validateOrganizationNotExists()) {
      this.$notify({
        title: this.$t("main.fill_organization"),
        type: "error",
      });
      setTimeout(() => {
        window.location = "/organization";
      }, 2000);
    }
    this.loading = true;
    OrganizationService.getG4Quiz(this.cogId)
        .then((org) => {
          const questions = org.data.filter(
              (q) => q.axle != "Conceitos e Relevância"
          );
          this.quiz = groupBy(questions, (q) => q.axle);
          this.axles = this.esg_labels;
          this.calculateEsgData();
          this.generateChart();

          const connects = JSON.parse(localStorage.getItem("map-connections"), true);
          if (connects) {
            const organizationId = JSON.parse(
                localStorage.getItem("organization"),
                true
            ).id;
            if (organizationId == null) {
              this.loading = false;
              return;
            }
            OrganizationService.saveMap(organizationId, connects)
                .catch(() => {
                  this.loading = false;
                  this.$notify({
                    title: this.$t("main.error_main"),
                    type: "error",
                  });
                })
                .then((response) => {
                  this.loading = false;
                  this.connects = response.data;
                  localStorage.removeItem("map-connections");
                  this.load();
                });
          } else {
            this.load();
          }

          if (window.screen.availWidth < 1025) {
            this.valor = 1.25;
            this.valor_map = 1.5;
            this.valor_graph = 350;
          }

        })
        .catch((e) => {
          this.loading = false;
          this.$notify({
            title: this.$t("common.error_http"),
            type: "error",
          });
        });

    // setInterval(function () {
    //   $("connection").connections("update");
    // }, 5);
  },
  computed: {
    canShare() {
      const invalidEmails =
        this.share.users.filter((user) => !this.isValidEmail(user.email))
          .length > 0;
      const invalidNames =
        this.share.users.filter((user) => !this.isValidName(user.name)).length >
        0;

      return !invalidEmails && !invalidNames;
    },
  },
  methods: {
    change_ods(value) {
      this.index = value;
      this.mostrar = false;

      this.mounted_chart(value);
    },
    open_ods() {
      return (this.open = !this.open);
    },
    create_pdf() {
      var a = document.createElement("a");
      a.setAttribute("target", "_blank");
      a.setAttribute("href", "/pdf");
      a.click();
    },
    mounted_chart(value) {
      this.loading = true;
      if (this.new_chart) {
        this.index = value;
        this.mostrar = false;
        this.loading = false;
      } else {
        this.new_chart = true;
        const self = this;

        const labelImage = this.colors.map((color) => {
          const odsNumber = this.ods_number_colors.find(
            (ods) => ods.color === color
          ).ods;
          const baseUrl = process.env.VUE_APP_S3_BUCKET_ASSETS;
          return {
            src: baseUrl + `ods-icon${odsNumber}.svg`,
            width: 60,
            height: 60,
          };
        });

        setTimeout(() => {
          this.chart = new Chart(document.getElementsByClassName("chart"), {
            type: "doughnut",
            data: {
              datasets: [
                {
                  data: this.series,
                  fill: false,
                  backgroundColor: this.colors,
                  borderColor: this.colors,
                },
              ],
            },
            options: {
              plugins: {
                datalabels: {
                  anchor: "end",
                  align: "start",
                },
                labels: {
                  render: "image",
                  textMargin: -60,
                  images: labelImage,
                },
              },
              tooltips: { enabled: false },
              legend: { display: false },
              responsive: false,
              onClick: function (e, activeElement) {
                var bar = this.getElementAtEvent(e)[0];
                var index = bar._index;

                self.chart.fillStyle = self.colors[self.index];

                self.mostrar = false;
                self.index = index;
                self.new_chart = false;

                this.update();
              },
            },
          });
          this.loading = false;
        }, 500);
      }
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    load() {
      this.ods_onu_links = require("../json/ods_links.json");
      this.loading = true;

      OrganizationService.getStepOne(AuthService.getCogId())
        .then((response) => {
          this.organizacao = response.data.organization;
          this.radar = response.data.radar
            .filter((r) => r.finalNote > 0)
            .sort((a, b) => b.finalNote - a.finalNote);
          this.mapa = response.data.map;

          if (this.radar.length == 0) {
            window.location = "/legacy";
          }

          // COMENTAR A PARTIR DAQUI

          // this.connects = _.chain(response.data.connects)
          //   .groupBy("stakeholder")
          //   .map((value, key) => ({ stakeholder: key, connections: value }))
          //   .map((value) => {
          //     if (value.connections.length > 0) {
          //       return {
          //         stakeholder: value.stakeholder,
          //         color: value.connections[0].color,
          //         connections: value.connections,
          //       };
          //     }
          //     return value;
          //   })
          //   .value();

          // this.connects.forEach((connect) => {
          //   this.colorsUsingForStakeholder.push(connect.color);
          //   connect.stakeholder = {
          //     value: connect.stakeholder,
          //     key: this.mapa.forWho.find(
          //       (item) => item.value == connect.stakeholder
          //     ).key,
          //   };
          // });

          // this.mapa.forWho.forEach((stakeholder) => {
          //   const map = _.cloneDeep(this.mapa);
          //   this.mapByStakeholders[stakeholder.value] = map;
          //   Object.keys(map).forEach((key) => {
          //     this.mapByStakeholders[stakeholder.value][key] = map[key];
          //   });
          // });

          // //Fill others stakeholders in connects because connects can have not all stalkeholders yet;
          // this.mapa.forWho.forEach((stakeholder) => {
          //   if (
          //     !this.connects.find(
          //       (connect) => connect.stakeholder.value === stakeholder
          //     )
          //   ) {
          //     const color = this.getColors();
          //     this.connects.push({
          //       stakeholder: { key: stakeholder.key, value: stakeholder.value },
          //       connections: [],
          //       color: color,
          //     });
          //   }
          // });

          // this.current_stakeholder =
          //   this.connects.length > 0 ? this.connects[0].stakeholder.value : "";

          // const connect = this.connects.find(
          //   (c) => c.stakeholder.value === this.current_stakeholder
          // );

          // connect.connections.forEach((connection) => {
          //   if (
          //     this.mapByStakeholders[this.current_stakeholder][
          //       connection.toSource
          //     ].filter((item) => item.key === connection.toKey).length == 0
          //   ) {
          //     this.mapByStakeholders[this.current_stakeholder][
          //       connection.toSource
          //     ].push({
          //       key: connection.toKey,
          //       value: connection.to,
          //     });
          //   }
          // });

          // this.changeCurrentStakeholder(this.current_stakeholder);

          // localStorage.setItem("connects", JSON.stringify(this.connects), true);

          //FINALIZAR COMENTÁRIO AQUI

          let sumNotes = 0;
          this.radar.forEach((x) => {
            sumNotes += x.finalNote;
          });

          this.radar.forEach((radar) => {
            radar.finalNote = parseFloat(
              (radar.finalNote / sumNotes) * 100
            ).toFixed(1);
            this.id.push(radar.ods);
            this.colors.push(radar.odsColor);
            this.series.push(parseFloat(radar.finalNote));
          });
          this.loading = false;
          this.mounted_chart();
        })
        .catch((e) => {
          if (e.response.status == 404) {
            this.$notify({
              title: this.$t("main.fill_organization"),
              type: "error",
            });
            setTimeout(() => {
              window.location = "/organization";
            }, 2000);
            return;
          }
          this.$notify({
            title: this.$t("main.error_main"),
            type: "error",
          });
          this.loading = false;
        });
    },
    changeMap(direction, key, item) {
      //If direction === to and It doesn't exists a from selected will unchecked all points that link himself.
      if (direction == "to" && this.current_from == null) {
        this.connects.every((connect) => {
          if (connect.stakeholder.value === this.current_stakeholder) {
            connect.connections = connect.connections.filter(
              (connection) => connection.toKey != item.key
            );

            const isKeyMapDefault = this.mapa[key]
              .map((m) => m.key)
              .includes(item.key);

            if (!isKeyMapDefault) {
              this.mapByStakeholders[this.current_stakeholder][key] =
                this.mapByStakeholders[this.current_stakeholder][key].filter(
                  (itemMap) => itemMap.key != item.key
                );
            }

            return false;
          }
          return true;
        });
      }

      //If direction === from save every things about this selected item for use in to
      if (direction == "from") {
        this.current_from = item;
        this.current_from_key = item.key;
        this.current_from_source = key;

        //himself link block
        this.disabled_click[key].from = true;
        this.disabled_click[key].to = true;

        //Block all from's and to's
        this.disabled_click = {
          activity: { from: true, to: true },
          effect: { from: true, to: true },
          forWho: { from: true, to: true },
          knowledgeProblem: { from: true, to: true },
          solution: { from: true, to: true },
          result: { from: true, to: true },
          impact: { from: true, to: true },
        };

        //Unblock just next pick up.
        switch (key) {
          case "forWho":
            this.disabled_click.knowledgeProblem.to = false;
            break;
          case "knowledgeProblem":
            this.disabled_click.solution.to = false;
            break;
          case "solution":
            this.disabled_click.activity.to = false;
            break;
          case "activity":
            this.disabled_click.effect.to = false;
            break;
          case "effect":
            this.disabled_click.result.to = false;
            break;
          case "result":
            this.disabled_click.impact.to = false;
            break;
          default:
            break;
        }
      }

      // If direction == to and have a current_from selected, then make the new link.
      if (direction == "to" && this.current_from) {
        this.connects.every((connect) => {
          if (connect.stakeholder.value === this.current_stakeholder) {
            let toKey = item.key;
            if (
              connect.connections.find(
                (connection) =>
                  connection.toKey === toKey &&
                  connection.fromKey === this.current_from_key
              )
            ) {
              return true;
            }
            if (
              connect.connections.find(
                (connection) => connection.toKey === toKey
              )
            ) {
              toKey = uuidv4();
              const map = _.cloneDeep(
                this.mapByStakeholders[this.current_stakeholder][key]
              );

              map.push({ key: toKey, value: item.value });
              this.mapByStakeholders[this.current_stakeholder][key] =
                _.cloneDeep(map);
            }

            connect.connections.push({
              color: connect.color,
              from: this.current_from.value,
              fromKey: this.current_from_key,
              fromSource: this.current_from_source,
              to: item.value,
              toKey: toKey,
              toSource: key,
              stakeholder: this.current_stakeholder,
              organizationId: this.organizacao.id,
              id: null,
            });

            return false;
          }
          return true;
        });

        this.current_from = null;
        this.current_from_key = null;
        this.current_from_source = null;

        //Unblock all from's and to's
        this.disabled_click = {
          activity: { from: false, to: false },
          effect: { from: false, to: false },
          forWho: { from: false, to: false },
          knowledgeProblem: { from: false, to: false },
          solution: { from: false, to: false },
          result: { from: false, to: false },
          impact: { from: false, to: false },
        };
      }

      this.makeConnectionsByStakeholder(this.current_stakeholder);

      const mapConnectionsForSave = [];

      this.connects.forEach((c) => {
        c.connections.forEach((connection) => {
          mapConnectionsForSave.push(connection);
        });
      });

      localStorage.setItem("connects", JSON.stringify(this.connects), true);
      localStorage.setItem(
        "map-connections",
        JSON.stringify(mapConnectionsForSave),
        true
      );
    },
    getColors() {
      const hexColor = _.sample(this.ods_colors);
      if (this.colorsUsingForStakeholder.includes(hexColor)) {
        this.getColors();
      }
      this.colorsUsingForStakeholder.push(hexColor);
      return hexColor;
    },
    getColorFromStakeholder(stakeholder) {
      const connect = this.connects.find(
        (c) => c.stakeholder.value === stakeholder.value
      );
      if (connect) {
        return connect.color + " !important";
      }
      return null;
    },
    verifyConnections(item, direction = "from") {
      const connect = this.connects.find(
        (c) => c.stakeholder.value === this.current_stakeholder
      );

      const keyDirection = _.camelCase(direction + " key");
      if (connect.connections.length > 0) {
        return (
          connect.connections.filter(
            (connection) => connection[keyDirection] === item.key
          ).length > 0
        );
      }
      return false;
    },
    getCurrentColorFromCurrentStakeholder(forWho = null) {
      if (forWho) {
        const connect = this.connects.find(
          (c) =>
            c.stakeholder.value === this.current_stakeholder &&
            c.stakeholder.value === forWho
        );
        if (connect) {
          return connect.color;
        }
      } else {
        return (
          this.connects.find(
            (c) => c.stakeholder.value === this.current_stakeholder
          ).color + " !important"
        );
      }
    },
    getColorFromCurrentStakeholder(item, direction) {
      const connect = _.cloneDeep(
        this.connects.find(
          (c) => c.stakeholder.value === this.current_stakeholder
        )
      );
      return this.verifyConnections(item, direction)
        ? connect.color + " !important"
        : "";
    },
    changeCurrentStakeholder(stakeholder) {
      this.current_stakeholder = stakeholder;
      this.makeConnectionsByStakeholder(this.current_stakeholder);
    },
    makeConnectionsByStakeholder(stakeholder) {
      // $("connection").remove();
      // const connect = this.connects.find(
      //   (c) => c.stakeholder.value === stakeholder
      // );
      // if (connect) {
      //   connect.connections.forEach((connection) => {
      //     const fromId = `#${connection.fromSource}-from-${connection.fromKey}`;
      //     const toId = `#${connection.toSource}-to-${connection.toKey}`;
      //     const self = this;
      //     setTimeout(() => {
      //       const color = self
      //         .getColorFromCurrentStakeholder(connect.stakeholder, "from")
      //         .replace(" !important", "");
      //       $(fromId).connections({
      //         to: toId,
      //         class: `${connection.fromSource}-from-${connection.fromKey} ${connection.toSource}-to-${connection.toKey} connection-border`,
      //         css: { "border-color": color },
      //       });
      //       self.$forceUpdate();
      //     }, 50);
      //   });
      // }
    },
    isValidEmail(email) {
      return Validators.validateEmail(email);
    },
    isValidName(name) {
      return Validators.validateName(name);
    },
    addShareUser() {
      this.share.users.push({ email: null, name: null });
    },
    removeShareUser(index) {
      if (this.share.users.length === 1) return;
      this.share.users.splice(index, 1);
    },
    chartBase64Image(data) {
      this.base64ImageFromChart = data;
    },
    blockMapEmail(val) {
      this.showMapEmail = val === "";
    },
    shareMap() {
      const prtHtml = document.getElementById("to-email").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      const style = `
        .cursor-pointer {
            cursor: pointer;
        }

        .seall-active {
            color: #533a8b !important;
        }

        .carousel {
            cursor: col-resize;
        }

        .img-chart {
          padding: 0vh 0vw;
          margin: 0vh 0vw;
          width: 100%;
          height: 100%;
          max-height: 70vh;
          max-width: 70vw;
          z-index: 10;
        }

        .chart2 {
          display: none;
          flex-direction: column;
          align-items: center;
          padding: 0vh 0vw;
          margin: 0vh 0vw;
          width: 100%;
          height: 100%;
          max-height: 70vh;
          max-width: 70vw;
          z-index: 10;
        }        

        html,
        body {
            border: 0vh 0vw;
            height: 100%;
            margin: 0vh 0vw;
            padding: 0vh 0vw;
            width: 100%;
        }

        .introduction-title{
          font-family: 'Poppins',sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 35px;
          line-height: 120%;
          color: #492e85;
        }

        .about-orgnanization-info {
            display: flex;
            flex-direction: row;
        }

        .about-orgnanization-info-orgimg {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 15%;
        }

        .about-orgnanization-info-orgimg img {
            width: 90%;
            border-radius: 50%;
        }

        .about-orgnanization-info-org {
            padding: 0vh 0vw 0vh 3vw;
            width: 30%;
        }

        .about-orgnanization-info-mission {
            width: 25%;
        }

        .about-orgnanization-info-img {}

        .about-orgnanization-info-img img {
            width: 50%;
        }

        .about-orgnanization h3 {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 35px;
            line-height: 120%;
            color: #533a8b;
            margin: 0vh 0vw;
            padding: 5vh 0vw 0vh;
        }

        .about-orgnanization-info h2 {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 120%;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #687d9b;
            margin: 0vh 0vw;
            padding: 1vh 0vw;
        }

        .about-orgnanization-info h3 {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 35px;
            line-height: 120%;
            color: #533a8b;
            margin: 0vh 0vw;
            padding: 1vh 0vw;
        }

        .about-orgnanization-info h4 {
            font-family: 'Nunito Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 25px;
            line-height: 120%;
            color: #32363b;
            margin: 0vh 0vw;
            padding: 1vh 0vw;
        }

        .about-orgnanization-info span {
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 130%;
            text-align: center;
            color: #32363b;
            margin: 0vh 0vw;
            padding: 1vh 0vw;
        }

        .about-orgnanization-info p {
            margin: 0.5vh 0vw;
            padding: 0.5vh 0.5vw;
            background-color: #f7f9fa;
            border: 1px solid #533a8b;
            border-radius: 48px;
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            color: #533a8b;
            opacity: 1;
            width: 50%;
        }

        .about-orgnanization-map {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 5vh 0vh;
            margin: 0vh 0vw;
            width: 100%;
        }

        .about-orgnanization-map i {
            border-radius: 50%;
        }

        .about-map h2 {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 35px;
            line-height: 120%;
            color: #492e85;
        }

        .about-map-line {}

        .about-map-radar-graph {
            padding: 0vh 2vw;
        }

        .about-map-radar-cards {
            display: table-row;
        }

        .about-map-radar-card {
            display: inline-block;
            margin: 0.7rem;
            height: 160px;
            border-radius: 10px;
            width: 160px;
        }

        .about-map-radar-text {
            height: 50%;
            margin: 1rem 0 0 1rem;
            display: inline-table;
            width: 100%;
            max-width: 145px;            
        }

        .about-map-radar-text > div {
            display: inline-block;
            vertical-align: text-top;
            text-align: left;
        }

        .about-map-radar-text-ods-number {
            width: auto;
        }

        .about-map-radar-text-ods-title {
            width: 70%;
            margin-left: 10px;
        }

        .about-map-radar-card p {
            padding: 0vh 0vw;
            margin: 0vh 0vw;
            color: #ffffff;
            font-family: 'Oswald', sans-serif;
            font-weight: bold;
            font-size: 24px;
            height: 100%;
        }

        .about-map-radar-card span {
            padding:0 ;
            color: #ffffff;
            font-family: 'Oswald', sans-serif;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: bold;
            width: 80%;
            height: 100%;
        }

        .about-map-radar-img {
            text-align: center;
            height: 50%;
        }

        .about-map-radar-img img {
            height: 70%;
        }

        .about-map-radar-chart {
        }

        .page-print-page-radar-chart-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            justify-items: center;
            width: 100%;
        }

        .mostrar-svg {
          position: absolute;
          margin-top: 17%;
          margin-left: 37%;
          z-index: 5;
        }

        .about-map-header {
            box-sizing: border-box;
            border-radius: 21.7826px;
            padding: 2rem;
        }

        .about-map-header-title {
            text-align: center;
        }

        .about-radar {
            display: table-row
        }

        .about-radar-ods-cards {
            display: table-row;
        }

        .about-radar-ods-card {
            border-color: #ffff;
            border-radius: 10px;
            border-style: solid;
            border-width: thin;
            margin: 2vh 1vw;
            width: 30%;
            display: inline-table;
        }

        .about-radar-ods-card-breakdown {
            margin-top: 30vh;
        }

        .about-radar-ods-text {
            display: block;
            background: #707780;
            color: #fff;
            border-radius: 10px 10px 0px 0px;
            padding: 12px 16px 6px;
        }

        .about-radar-ods-text img {
            padding: 10px;
            width: 15%;
            display:inline-block;
            vertical-align: middle;
        }

        .about-radar-ods-text span {
            display:inline-block;
            vertical-align: middle;
            font-family: 'Oswald', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 3vh;
            line-height: 120%;
            text-align: center;
            color: #fff;
            width: 10%;
        }

        .about-radar-ods-text h2 {
            display:inline-block;
            vertical-align: middle;
            font-family: 'Oswald', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 2vh;
            line-height: 110%;
            color: #fff;
            width: 47%;
            margin: 0vh 0vw;
        }

        .about-radar-ods-text p {
            display:inline-block;
            vertical-align: middle;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 2.3vh;
            line-height: 120%;
            color: #fff;
            width: 20%;
            margin: 0vh 0vw;
            padding: 0vh 0vw 0vh 0.5vh;
            text-align: right;
        }

        .about-radar-ods-bar {
            height: 5px;
        }

        .about-radar-ods-desc h2 {
            border-color: #e6e9ed;
            border-radius: 0px 0px 10px 10px;
            border-style: solid;
            border-width: 3px;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 133%;
            text-align: center;
            color: #666666;
            margin: 0vh 0vw;
            padding: 1vh 2vw;
            min-height: 10vh;
        }`;

      const bodyEmail = this.share.users.map((user) => {
        const HTML = `<!DOCTYPE html><html>
            <head>   
              <link rel="preconnect" href="https://fonts.googleapis.com">
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
              <link href="https://fonts.googleapis.com/css2?family=Nunito&family=Nunito+Sans&family=Oswald&family=Poppins&display=swap" rel="stylesheet">
              <style>
                ${style}
              </style>
            </head>
            <body>
              <h2 class="introduction-title">
                ${this.$t("map.share.hello")} ${user.name},
              </h2>
              <p>
                ${this.$t("map.share.the")} ${this.organizacao.name} ${this.$t(
          "map.share.bodyEmail"
        )}
              </p>
              <br>
              ${prtHtml}
            </body>
          </html>`;

        return {
          emailTo: user.email,
          htmlBody: HTML,
          subject: "Compartilhamento Mapa da Transformação - SEALL",
        };
      });

      const bodytoSend = {
        emails: bodyEmail,
        radarImage: this.base64ImageFromChart,
        uuid: this.organizacao.id + ".png",
      };

      this.loading = true;
      bodytoSend.bucket =
        process.env.NODE_ENV != "dev"
          ? "seall-freemium-frontend/map-shared-email"
          : "seall-freemium-frontend-validation/map-shared-email";

      SeallBasicLambdasService.sendEmail(bodytoSend)
        .then((t) => {
          window.location = "#close";

          this.$notify({
            title: this.$t("map.share.email_send_msg"),
            type: "success",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openEsgAxle() {
      return (this.open_esg = !this.open_esg);
    },
    generateChart() {
      const labelImage = [
        {
          src:
              process.env.VUE_APP_S3_BUCKET_ASSETS + 'esg-ambiente.png',
          width: 40,
          height: 40,
        },
        {
          src: process.env.VUE_APP_S3_BUCKET_ASSETS + 'esg-governanca.png',
          width: 40,
          height: 40,
        },
        {
          src: process.env.VUE_APP_S3_BUCKET_ASSETS + 'esg-social.png',
          width: 40,
          height: 40,
        },
      ];

      const self = this;

      setTimeout(() => {
        this.chart_esg = new Chart(document.getElementsByClassName("chart-esg"), {
          type: "doughnut",
          data: {
            labels: self.labels,
            datasets: [
              {
                data: this.esgData,
                fill: false,
                backgroundColor: self.esgColors,
                borderColor: self.esgColors,
              },
            ],
          },
          options: {
            plugins: {
              datalabels: {
                anchor: "end",
                align: "start",
              },
              labels: {
                render: "image",
                textMargin: -60,
                images: labelImage,
              },
            },
            tooltips: { enabled: false },
            legend: { display: false },
            responsive: false,
            onClick: function (e, activeElement) {
              var bar = this.getElementAtEvent(e)[0];
              var index = bar._index;

              self.chart_esg.fillStyle = self.esgColors[self.index_esg];

              self.mostrar_esg = false;
              self.index_esg = index;

              this.update();
            },
          },
        });
      }, 500);
    },
    change_axle_esg(index) {
      this.index_esg = index;
      this.mostrar_esg = false;
    },
    calculateEsgData() {
      let sumTotal = 0;
      const answerByAxle = [];
      this.axles.forEach((ax) => {
        const questions = this.quiz[ax];
        let sumRating = 0;
        questions.forEach((question) => {
          const answer = question.answer;
          const optionAnswer = question.options.find(
              (opt) => opt.id === answer.answerId
          );
          const ratingOfAnswer = optionAnswer ? optionAnswer.rating : 0;
          sumRating = sumRating + ratingOfAnswer;
        });
        sumTotal = sumTotal + sumRating;
        answerByAxle.push(sumRating);
        this.esgDataIndividual.push((sumRating * 100) / (5 * questions.length));
      });

      answerByAxle.forEach((a) => {
        this.esgData.push((a * 100) / sumTotal);
      });
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Loading,
    MapEmail,
    SeallHeader,
    PoweredBy,
    CarouselCards,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: Lato-Regular;
  src: url(../fonts/Lato/Lato-Regular.ttf);
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
}

.carousel-cards {
  background: transparent;
}

.checkbox input,
.checkbox svg {
  width: 21px;
  height: 21px;
  display: block;
}

.checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  background: #fff;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, #d1d6ee);
}

.checkbox input:hover {
  --s: 2px;
  --b: #bbc1e1;
}

.checkbox input:checked {
  --s: 2px;
  --b: #bbc1e1;
  background-color: #ab92fa;
}

.bg-checked {
  color: #ab92fa !important;
}

.checkbox svg {
  pointer-events: none;
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--stroke, #1e2235);
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  transform: scale(var(--scale, 1)) translateZ(0);
}

.checkbox .path input:checked {
  --s: 2px;
  transition-delay: 0.4s;
}

.checkbox .path input:checked + svg {
  --a: 16.1 86.12;
  --o: 102.22;
}

.checkbox .path svg {
  stroke-dasharray: var(--a, 86.12);
  stroke-dashoffset: var(--o, 86.12);
  transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
}

.checkbox .bounce {
  --stroke: #fff;
}

.checkbox .bounce input:checked {
  --s: 11px;
}

.checkbox .bounce input + svg {
  animation: bounce 0.4s linear forwards 0.2s;
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.chart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.map-radar-graph-itens {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
}

.mostrar-svg {
  position: absolute;
  z-index: 5;
}

.mostrar-div {
  width: 222px;
  height: 222px;
  position: absolute;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 6vh;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  box-shadow: 1px 0px 10px 5px rgba(255, 255, 255, 0.75);
}

.mostrar-div p {
  margin: 0 !important;
  font-family: Poppins-Regular !important;
}

.chart_esg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-map {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 3vw;
}

.div-map-header-links {
  text-decoration: none;
}

.map-header-link {
  color: #a3a3a3;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  padding: 0vh 2vw;
  text-decoration: none;
}

.map-header-button {
  color: #2f1764;
  background-color: #ffffff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 0vw;
  padding: 1vh 3vw;
}

.material-icons {
  color: #000000;
}

.div-map-header-photo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
}

.div-map-header-photo i {
  padding: 0vh 1vw 0vh 3vw;
}

.div-map-header-photo img {
  border-radius: 100%;
  overflow: hidden;
  height: 10%;
  width: 10%;
}

.main-map {
  background-color: #e5e5e5;
}

.div-legacy-cards {
  background-image: url("./../images/samepeople2.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2vh 0vw 2vh 0vw;
  min-height: 250px;
}

.legacy-card {
  z-index: 9999;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  min-height: 80px;
  max-height: 80px;
  margin: 0vh 1vw;
  padding: 5vh 1vw;
  width: 25vw;
}

.legacy-card p {
  color: #687d9b;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  padding: 0vh 0.5vw;
}

.legacy-card img {
  width: 4vw;
}

.div-legacy-cards-img {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 9998;
  align-items: center;
  margin-top: 3rem;
  width: 100%;
}
.div-legacy-cards-img img {
  height: 250px;
  width: 250px;
  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.5s;
}

.page-map-results {
  background-color: #ffffff;
  padding-top: 260px;
}

.map-infos-organization {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.div-material-icons {
  padding: 2vh 0vw;
}

.div-material-icons .material-icons {
  padding: 0vh 2vw;
  color: #e0e0e0;
}

.map-infos-organization h2 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 133%;
  text-align: center;
  color: #533a8b;
  margin: 0vh 0vw;
  padding: 1vh 0vw;
}

.map-infos-organization h3 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 133%;
  text-align: center;
  color: #533a8b;
  margin: 0vh 0vw;
  padding: 1vh 0vw;
}

.map-infos-organization span {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 125%;
  text-align: center;
  color: #32363b;
  opacity: 0.4;
  margin: 0vh 0vw;
  padding: 1vh 0vw;
}

.map-infos-organization-line {
  display: flex;
  flex-direction: row;
  padding: 1vh 0vw;
}

.map-infos-organization-line p {
  margin: 0vh 0.5vw;
  padding: 0.5vh 3vw;
  background-color: #f7f9fa;
  border: 1px solid #533a8b;
  border-radius: 48px;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #533a8b;
  opacity: 1;
}

.map-missao-organization {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e5e5e5;
  min-width: 100%;
  padding: 10vh 0vw;
}

.map-missao-organization h2 {
  margin: 0vh 0vw;
  padding: 2vh 0vw;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 133%;
  text-align: center;
  color: #492e85;
}

.map-missao-organization h3 {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 133%;
  text-align: center;
  color: #666666;
  width: 60%;
}

.map-infos-map {
  background-color: #ffffff;
  padding: 5vh 0vw 2vh;
  width: 100%;
}

.map-infos-map h2 {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 133%;
  text-align: center;
  color: #492e85;
}

.map-infos-map h3 {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 133%;
  text-align: center;
  color: #a3a3a3;
}

.map-points {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
}

.map-points-line {
  padding: 5vh 2vw;
  width: 100%;
  text-align: left;
}

.map-points-card {
  border: 1px solid #533a8b;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: flex-start;
  background-color: #ffffff;
  margin: 1.5vh 0vw;
  padding: 0vh 0.5vw;
}

.map-points-card input {
  border-radius: 50%;
}

.map-points span {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 170%;
  color: #533a8b;
}

.map-points p {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 115%;
  display: flex;
  align-items: center;
  color: #533a8b;
  padding: 0vh 1vw;
  text-align: center;
}

.map-radar-ods {
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 0vw;
}

.map-radar-ods h2 {
  margin: 0vh 0vw;
  padding: 2vh 0vw;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 133%;
  text-align: center;
  color: #492e85;
}

.map-radar-ods h3 {
  margin: 0vh 0vw;
  padding: 0vh 0vw 3vh;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 133%;
  text-align: center;
  color: #a3a3a3;
}

.map-radar-ods-graph {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
  /*background: #FFFFFF;*/
  /*box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2), 0px 6px 10px rgba(112, 119, 128, 0.14), 0px 1px 18px rgba(112, 119, 128, 0.12);*/
  border-radius: 16px;
  padding: 4vh 2vw;
}

.map-radar-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  text-align: left;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}

.map-radar-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-align: left;
  padding: 1vh 0.5vw;
  margin: 0vh 0.5vw;
  height: 140px;
  border-radius: 10px;
  width: 140px;
  margin-bottom: 1rem;
}

.map-radar-card-text {
  display: flex;
  flex-direction: row;
  height: 30%;
  width: 100%;
  padding: 0.5vh 0vw;
}

.map-radar-card p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: flex-start;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-size: 28px;
}

.map-radar-card span {
  display: flex;
  align-items: flex-start;
  padding: 5px 0 0 5px;
  margin: 0vh 0vw;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}

.map-radar-card-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  height: 80%;
}

.map-radar-card-img img {
  height: 80%;
}

.map-radar-graph {
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-radar-graph img {
  width: 90%;
}

.map-radar-ods-details {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 5vh 0vw;
  width: 100%;
}

.map-radar-details {
  box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2),
    0px 6px 10px rgba(112, 119, 128, 0.14),
    0px 1px 18px rgba(112, 119, 128, 0.12);
  align-items: center;
  border-radius: 5vh 5vh;
  display: flex;
  flex-direction: column;
  width: 60vw;
}

.map-radar-details-title {
  border-radius: 5vh 5vh 0 0;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 2vh 2vw;
  width: 100%;
}

.map-radar-details-title .material-icons {
  color: #ffffff;
}

.map-radar-details-title img {
  width: 10%;
}

.map-radar-details-title h2 {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 56px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  width: 10%;
}

.map-radar-details-title h3 {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 120%;
  color: #ffffff;
  text-align: left;
  width: 70%;
}

.map-radar-details-title p {
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  opacity: 1;
  width: 10%;
}

.map-radar-details-description {
  background-color: #ffffff;
  padding: 2vh 2vw;
  width: 100%;
}

.map-radar-details-description span {
  cursor: pointer;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #a3a3a3;
  padding: 0vh 5vw;
}

.map-radar-details-description-sub {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 133%;
  color: #666666;
  text-align: left;
  padding: 0vh 5vw;
}

.map-radar-details-description-desc {
  font-family: NunitoSans-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 160%;
  color: #707780;
  text-align: left;
  padding: 0vh 5vw;
}

.map-radar-details-line {
  width: 100%;
  background: #e6e9ed;
}

.map-radar-details-line-color {
  height: 1vh;
}

.map-radar-details-footer {
  align-items: center;
  border-radius: 0 0 5vh 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  padding: 2vh 2vw;
  width: 100%;
  height: 10vh;
}

.map-radar-details-footer img {
  width: 5%;
}

.map-radar-details-footer h2 {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 2.5vw;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: #707780;
}

.map-radar-details-footer h3 {
  margin: 0vh 0vw;
  padding: 0vh 1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 120%;
  color: #707780;
  width: 70%;
}

.map-radar-details-footer .material-icons {
  color: #707780;
  width: 10%;
}

.footer-map {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.footer-map svg {
  width: 5%;
  height: 1vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw 2vh;
}

.footer-map rect {
  width: 100%;
  height: 1vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.div-map-footer-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5vh 2vw;
  align-items: center;
  button {
    width: 250px;
    min-height: 45px;
    margin: 5px;
  }
  a {
    text-decoration: auto;
    width: 250px;
    min-height: 45px;
  }
  .actions {
    display: flex;
  }
}

.div-map-footer-button .material-icons {
  color: #2f1764;
}

.map-footer-button-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2f1764;
  background-color: #ffffff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 1vw;
  padding: 1vh 1vw;
}

.map-footer-button {
  color: #ffffff;
  background-color: #2f1764ff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  padding: 1vh 3vw;
}

.modalDialog {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  opacity: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
  &:target {
    opacity: 1;
    pointer-events: auto;
  }
  & > .share-modal {
    width: 90%;
    margin: 5%;
    max-height: 90vh;
    position: relative;
    padding: 56px;
    border-radius: 10px;
    background: #fff;
    background: -moz-linear-gradient(#fff, #999);
    background: -webkit-linear-gradient(#fff, #999);
    background: -o-linear-gradient(#fff, #999);
    & > .share-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .title {
        text-align: left;
        max-width: 63%;
        & > h2 {
          font-family: Poppins-Regular;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 115%;
          color: #533a8b;
          margin-block: 0px;
        }
        & > sub {
          font-family: NunitoSans-Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 150%;
          margin-top: 5px;
        }
      }

      & .material-icons {
        color: #533a8b;
      }
    }
    & > .share-modal-content {
      display: flex;
      margin-top: 3rem;
      width: 100%;
      & > .share-form {
        width: 100%;
        min-height: 400px;
        & > .form-content {
          max-height: 280px;
          overflow: auto;
          & > .form-row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 1em;
            & .form-item {
              display: flex;
              flex-direction: column;
              font-family: NunitoSans-Regular;
              width: 45%;
              & > label {
                margin-bottom: 0.5rem;
                text-align: left;
                display: inline-block;
                &.required:after {
                  content: " *";
                  color: #00b9bd;
                }
              }
              & > input {
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;
              }
              & > .invalid-feedback {
                width: 100%;
                margin-top: 0.25rem;
                font-size: 0.875em;
                color: #dc3545;
                text-align: left;
              }
            }
            & > .btn-delete {
              background: transparent;
              border: 0;
              text-align: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              cursor: pointer;
              display: flex;
              align-items: center;
              align-self: center;
              color: #9d9d9c;
              font-size: 18px;
              margin-top: 1.7rem;
            }
          }
        }

        .btn-add {
          border-radius: 50%;
          background-color: #2f1764ff;
          border-width: 0;
          position: absolute;
          right: 56px;
          cursor: pointer;
          width: 36px;
          height: 36px;
          top: 20%;
          .material-icons {
            color: #fff;
            font-size: 16px;
            vertical-align: middle;
          }
        }

        .btn-actions {
          bottom: 1rem;
          position: absolute;
          display: flex;
          align-items: center;
          width: calc(100% - 80px);
          justify-content: space-between;
          flex-wrap: wrap;
          .btn-cancel {
            font-family: NunitoSans-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 100%;
            text-align: center;
            color: #9d9d9c;
            border: 0;
            padding: 1vh 2vw;
            background-color: #fff;
            cursor: pointer;
          }
          .btn-share {
            color: #ffffff;
            background-color: #2f1764ff;
            border-color: #2f1764;
            border-radius: 5px;
            border-style: solid;
            border-width: thin;
            font-family: Nunito-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            margin: 0vh 0vw;
            padding: 1vh 2vw;
            cursor: pointer;
          }
          button:disabled {
            opacity: 0.85;
          }
        }
      }
    }
  }
}

.modalDialog h3 {
  color: #32363b;
  font-family: NunitoSans-Regular;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin: 0vh 0vw;
  padding-bottom: 2vh;
}

.mostrar-svg-esg {
  position: absolute;
  z-index: 5;
  width: 10%;
  height: 10%;
}

.mostrar-div-esg {
  width: 222px;
  height: 222px;
  position: absolute;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 6vh;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  box-shadow: 1px 0px 10px 5px rgba(255, 255, 255, 0.75);
  p {
    margin: 0 !important;
    font-family: Poppins-Regular !important;
  }
  img {
    width: 80px;
    height: 80px;
  }
}

@media screen and (min-width: 300px) and (max-width: 650px) {
  .mostrar-div {
    font-size: 3vh;
    width: 150px;
    height: 150px;
  }

  .modalDialog {
    .form-row {
      flex-direction: column;
      .form-item {
        margin-bottom: 1rem;
        width: 100% !important;
      }
      .btn-delete {
        margin-top: 0px !important;
      }
    }
    .btn-actions {
      a {
        margin-left: 25%;
      }
    }
  }
}
@media screen and (min-width: 651px) and (max-width: 799px) {
  .mostrar-div {
    font-size: 3vh;
    width: 25%;
    height: 35%;
  }
}
@media screen and (min-width: 800px) and (max-width: 1025px) {
  .mostrar-div {
    font-size: 4vh;
    width: 25%;
    height: 35%;
  }
}
@media screen and (max-width: 1025px) {
  html,
  body {
    border: 0vh 0vw;
    height: 10%;
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-map {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .btn-add {
    top: 25% !important;
  }

  .div-map-header-links {
    text-decoration: none;
    padding: 3vh 0vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .div-map-header-photo {
    justify-content: center;
    width: 100%;
  }

  .div-map-header-photo img {
    border-radius: 100%;
    overflow: hidden;
    height: 10%;
    width: 10%;
  }

  .div-map-header-img img {
    width: 40vw;
  }

  .legacy-card {
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    height: 10vh;
    margin: 0vh 0vw;
    padding: 5vh 3vw;
    width: 75vw;
  }

  .legacy-card img {
    width: 10vw;
  }

  .map-missao-organization h3 {
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
  }

  .map-radar-card {
    margin: 0.5vh 5vw;
    width: 35vw;
    height: 20vh;
  }

  .map-radar-details {
    width: 90vw;
  }

  .map-radar-details h2 {
    font-size: 30px;
  }

  .map-radar-details h3 {
    font-size: 20px;
  }

  .map-radar-details-line {
    width: 100%;
    background: #e6e9ed;
  }

  .map-radar-details-line-color {
    left: 0vw;
  }

  .map-radar-details-description-sub {
    font-size: 20px;
  }

  .map-radar-details-description-dec {
    font-size: 15px;
  }

  .map-radar-details-footer h2 {
    font-size: 20px;
  }

  .map-radar-details-footer h3 {
    padding: 0vh 3vw;
    font-size: 15px;
  }

  .footer-map {
    margin: 0vh 0vw;
    padding: 0vh 0vw;
  }

  .div-map-footer-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1vh 0vw;
    margin: 0vh 0vw;
  }

  .div-map-footer-button .material-icons {
    color: #2f1764;
  }

  .map-footer-button-back {
    color: #2f1764;
    background-color: #ffffff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 1vh 0vw;
    padding: 0vh 1vw;
    width: 60vw;
  }

  .map-footer-button {
    color: #ffffff;
    background-color: #2f1764ff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 1vh 0vw;
    padding: 0.5vh 3vw;
    width: 75vw;
  }

  .div-map-footer-button {
    button,
    a {
      width: 200px;
    }

    .actions {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
    }
  }

  .mostrar-svg {
    position: absolute;
    z-index: 5;
    width: 20%;
  }

  .mostrar-svg-esg {
    position: absolute;
    z-index: 5;
    width: 10%;
    height: 10%;
  }

  .mostrar-div img {
    width: 15vw;
  }

  .mostrar-div-esg img {
    width: 15vw;
  }
}
</style>